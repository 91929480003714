.withdrwal-list {
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  flex: 4;
  padding: 2rem;
  margin: 1rem;
}

.userListUser {
  display: flex;
  align-items: center;
}



.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.userListDelete{
    color: red;
    cursor: pointer;
}

.withdrwal-list table{
  border: 0.1px solid rgb(205, 203, 203);
  border-collapse: collapse;
}
.withdrwal-list th{
  border: 0.1px solid rgb(205, 203, 203);
  border-collapse: collapse;
  text-align: center;
}
.withdrwal-list tr{
  border: 0.1px solid rgb(205, 203, 203);
  border-collapse: collapse;
}
.withdrwal-list td{
  text-align: center;
  border: 0.1px solid rgb(205, 203, 203);
  border-collapse: collapse;
}

