.signin {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../images/login/7.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
}
.inner-signup{
    background-color: #fff;
    height: auto;
    max-width: 450px;
    min-width: 300px;
    margin: 2rem;
    padding: 2rem;
    border-radius: 6px;
}

.inner-signup input {
    width: 100%;
    height: 100%;
    min-height: 2rem;
    min-width: 250px;
    border-radius: 4px;
    margin: 1rem 0 0 0;
    padding: .6rem;
    outline: none;
    border: none;
    font-size: 1rem;
    background-color: rgba(220, 218, 218, 0.532)
}
.inner-signup button{
    height: auto;
    padding: 1rem;
    margin: 2rem 1rem;
    color: #fff;
    background-color:#525FE1;
    text-align: center;
    font-size: 1rem;
    outline: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}
.inner-signup button:hover{
    background-color:#151a4c;
}